import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import fetchData from '../thunks/dataFetchThunk';

const nomenclaturesFieldsAdapter = createEntityAdapter();
const initialState = nomenclaturesFieldsAdapter.getInitialState({ selectedNomenclatureId: null });
const nomenclaturesFieldsSlice = createSlice({
  name: 'nomenclaturesFields',
  initialState,
  reducers: {
    addNomenclatureFields: nomenclaturesFieldsAdapter.addOne,
    editNomenclatureFields: nomenclaturesFieldsAdapter.updateOne,
    removeNomenclatureFields: (state, action) => {
      nomenclaturesFieldsAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.fulfilled, (state, { payload }) => {
        const { nomenclaturesFields } = payload;
        const newData = {};
        // eslint-disable-next-line no-return-assign
        nomenclaturesFields.map((item, index) => newData[index + 1] = item);
        nomenclaturesFieldsAdapter.addMany(state, newData);
      });
  },
});
export const {
  addNomenclatureFields,
  editNomenclatureFields,
  removeNomenclatureFields,
} = nomenclaturesFieldsSlice.actions;
export const selectors = nomenclaturesFieldsAdapter.getSelectors((state) => state.nomenclaturesFields);
export default nomenclaturesFieldsSlice.reducer;
