import React, { useEffect, useRef } from 'react';
import { FloatingLabel, Form, Button } from 'react-bootstrap';
import * as yup from 'yup';
import { useFormik } from 'formik';
import './outputForm.css';
import { useDispatch, useSelector } from 'react-redux';
import { PlusLg, XLg } from 'react-bootstrap-icons';
import { cloneDeep } from 'lodash';
import {
  addNomenclature,
  selectors,
} from '../../../../slices/nomenclaturesSlice';
import {
  addNomenclatureFields,
  editNomenclatureFields,
  selectors as nomenclaturesFieldsSelectors,
} from '../../../../slices/nomenclaturesFieldsSlice';
import {
  addNomenclatureRolls, removeNomenclatureRolls,
  selectors as nomenclaturesRollsSelectors,
} from '../../../../slices/nomenclaturesRollsSlice';
import routes, { getRoutes } from '../../../../utils/routes';
import setData from '../../../../hooks/setData';
import { changeLoaderVisibility } from '../../../../slices/loaderSlice';
import getLastId from '../../../../hooks/getLastId';

function OutputForm(props) {
  const {
    selectedTaste, selectedProduct, selectedCategory, operationId, resetData,
  } = props;

  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  const SignupSchema = yup.object({
    doughBatches: yup.number()
      .min(0, 'Значение должно быть 0 или больше'),
    souffleMixes: yup.number()
      .min(0, 'Значение должно быть 0 или больше'),
    boxes: yup.number()
      .min(0, 'Значение должно быть 0 или больше'),
    count: yup.number()
      .min(0, 'Значение должно быть 0 или больше'),
    rolls: yup.array().of(
      yup.object().shape({
        rollWeightStart: yup.number()
          .required('Поле должно быть заполнено')
          .min(1, 'Значение должно быть больше 0'),
        rollWeightEnd: yup.number()
          .required('Поле должно быть заполнено')
          .min(0, 'Значение должно быть 0 или больше'),
        sleeveWeight: yup.number()
          .when('rollWeightEnd', {
            is: 0,
            then: yup.number().required('Поле должно быть заполнено'),
          })
          .min(1, 'Значение должно быть больше 0'),
      }),
    ),
  });

  const dispatch = useDispatch();
  const selectedNomenclatureId = useSelector((state) => state.nomenclatures.selectedNomenclatureId);
  const nomenclatures = useSelector(selectors.selectAll);
  const nomenclaturesFields = useSelector(nomenclaturesFieldsSelectors.selectAll);
  const nomenclaturesRolls = useSelector(nomenclaturesRollsSelectors.selectAll);
  const selectedNomenclature = nomenclatures.filter((item) => item.id === selectedNomenclatureId)[0];

  const selectedNomenclatureFields = nomenclaturesFields.filter((field) => field.nomenclatureId === selectedNomenclatureId)[0];
  const selectedNomenclatureRolls = nomenclaturesRolls.filter((roll) => roll.nomenclatureId === selectedNomenclatureId);

  const initialValues = selectedNomenclature ? {
    doughBatches: selectedNomenclatureFields.doughBatches,
    souffleMixes: selectedNomenclatureFields.souffleMixes,
    boxes: selectedNomenclatureFields.boxes,
    count: selectedNomenclatureFields.count,
    rolls: selectedNomenclatureRolls,
  } : {
    doughBatches: '', souffleMixes: '', boxes: '', count: '', rolls: [],
  };

  const f = useFormik({
    initialValues,
    validationSchema: SignupSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      const date = new Date(new Date().getTime() + 7 * 3600 * 1000);
      const addDate = date.toISOString().split('.')[0];
      const type = selectedNomenclatureId ? 'edit' : 'add';
      if (selectedNomenclatureId) {
        // EDITING NOMENCLATURE
        const fields = nomenclaturesFields.filter((field) => field.nomenclatureId === selectedNomenclatureId)[0];
        const nomenclatureValues = {
          doughBatches: values.doughBatches,
          souffleMixes: values.souffleMixes,
          boxes: values.boxes,
          count: values.count,
        };

        const rollsIds = selectedNomenclatureRolls.map((item) => Number(item.id));

        dispatch(changeLoaderVisibility(true));
        let lastRollsId = await getLastId(getRoutes.getLastNomenclaturesRollsId());
        dispatch(changeLoaderVisibility(false));

        const rolls = cloneDeep(values.rolls);
        const newRolls = rolls.map((roll) => {
          lastRollsId += 1;
          roll.id = lastRollsId;
          roll.nomenclatureId = selectedNomenclatureId;
          return roll;
        });
        const rollsValues = { ...newRolls };

        const preparedValues = {
          id: selectedNomenclatureId, ...values, ...nomenclatureValues, rolls: newRolls, fieldsId: fields.id, type,
        };

        const action = () => {
          dispatch(editNomenclatureFields({
            id: fields.id,
            changes: nomenclatureValues,
          }));
          dispatch(removeNomenclatureRolls(rollsIds));
          dispatch(addNomenclatureRolls(rollsValues));
        };

        dispatch(changeLoaderVisibility(true));
        await setData(routes.setNomenclaturePath(), action, preparedValues, null, {}, true);
        dispatch(changeLoaderVisibility(false));
        // ADDING NOMENCLATURE
      } else {
        dispatch(changeLoaderVisibility(true));
        const lastId = await getLastId(getRoutes.getLastNomenclaturesId());
        const id = Number(lastId) + 1;
        dispatch(changeLoaderVisibility(false));

        const nomenclatureValues = {
          id,
          addDate,
          categoryId: selectedCategory.id,
          productId: selectedProduct.id,
          tasteId: selectedTaste.id,
          createdOperationId: operationId,
          type,
        };

        dispatch(changeLoaderVisibility(true));
        const lastFieldsId = await getLastId(getRoutes.getLastNomenclaturesFieldsId());
        const fieldsId = Number(lastFieldsId) + 1;
        dispatch(changeLoaderVisibility(false));

        const fieldsValue = {
          id: fieldsId,
          nomenclatureId: id,
          doughBatches: values.doughBatches,
          souffleMixes: values.souffleMixes,
          boxes: values.boxes,
          count: values.count,
        };

        dispatch(changeLoaderVisibility(true));
        let lastRollsId = await getLastId(getRoutes.getLastNomenclaturesRollsId());
        dispatch(changeLoaderVisibility(false));

        const rolls = cloneDeep(values.rolls);
        const newRolls = rolls.map((roll) => {
          lastRollsId += 1;
          roll.id = lastRollsId;
          roll.nomenclatureId = id;
          return roll;
        });
        const rollsValues = { ...newRolls };

        const preparedValues = {
          ...values, ...nomenclatureValues, rolls: newRolls, fieldsId,
        };
        const action = () => {
          dispatch(addNomenclature(nomenclatureValues));
          dispatch(addNomenclatureFields(fieldsValue));
          dispatch(addNomenclatureRolls(rollsValues));
        };
        dispatch(changeLoaderVisibility(true));
        await setData(routes.setNomenclaturePath(), action, preparedValues, null, {}, true);
        dispatch(changeLoaderVisibility(false));
      }
      resetData();
    },
  });

  const selectedNomenclatureTitle = selectedProduct && selectedTaste
    ? `${selectedCategory.name}, ${selectedProduct.name}, ${selectedTaste.name}`
    : '';

  const {
    values, setValues, errors, touched, setFieldValue,
  } = f;

  const onAddNewRollHandle = async () => {
    const rolls = [...values.rolls];
    const lastRollsId = rolls.length > 0 ? Number(rolls[rolls.length - 1].id) + 1 : 1;
    const id = lastRollsId + 1;

    rolls.push({
      id, rollWeightStart: '', rollWeightEnd: '', sleeveWeight: '',
    });
    setValues({ ...values, rolls });
  };

  const onRemoveRollHandle = (rollId) => {
    const rolls = [...values.rolls];
    const newRolls = rolls.filter((roll) => roll.id !== rollId);
    setValues({ ...values, rolls: newRolls });
  };

  const rollsArray = f.values.rolls;
  const rollsArrayLength = rollsArray.length;

  const lastRollWeightStart = rollsArrayLength > 0 ? rollsArray[rollsArrayLength - 1].rollWeightStart : null;
  const lastRollWeightEnd = rollsArrayLength > 0 ? rollsArray[rollsArrayLength - 1].rollWeightEnd : null;
  const lastRollSleeveWeight = rollsArrayLength > 0 ? rollsArray[rollsArrayLength - 1].sleeveWeight : null;

  const isAddRollButtonEnabled = (lastRollWeightStart > 0
    && Number(lastRollWeightEnd) === 0
    && lastRollSleeveWeight > 0)
    || rollsArrayLength === 0;

  React.useEffect(() => {
    if (Number(lastRollWeightEnd) !== 0) {
      setFieldValue(`rolls.${rollsArrayLength - 1}.sleeveWeight`, '');
    }
  }, [lastRollWeightEnd, rollsArrayLength, setFieldValue]);

  return (
    <Form
      onSubmit={f.handleSubmit}
      className="output-form">

      <p
        className="output-form__description">
        Вы выбрали:

        {' '}

        {selectedNomenclatureTitle}
      </p>

      <div
        className="output-form__grid output-form__grid--x2">

        <FloatingLabel
          label="Количество замесов теста"
          controlId="doughBatches"
          className="output-form__field">

          <Form.Control
            name="doughBatches"
            type="number"
            placeholder="Введите количество замесов теста"
            ref={inputRef}
            min={0}
            step={0.25}
            value={values.doughBatches}
            onChange={f.handleChange}
            isInvalid={touched.doughBatches && !!errors.doughBatches}
          />

          <Form.Control.Feedback
            type="invalid">
            {errors.doughBatches ? errors.doughBatches : null}
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel
          label="Количество замесов суфле"
          controlId="souffleMixes"
          className="output-form__field">

          <Form.Control
            name="souffleMixes"
            type="number"
            placeholder="Введите количество замесов суфле"
            min={0}
            step={0.25}
            value={values.souffleMixes}
            onChange={f.handleChange}
            isInvalid={touched.souffleMixes && !!errors.souffleMixes}
          />

          <Form.Control.Feedback
            type="invalid">
            {errors.souffleMixes ? errors.souffleMixes : null}
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel
          label="Количество боксов"
          controlId="boxes"
          className="output-form__field">

          <Form.Control
            name="boxes"
            type="number"
            placeholder="Введите количество боксов"
            min={0}
            value={values.boxes}
            onChange={f.handleChange}
            isInvalid={touched.boxes && !!errors.boxes}
          />

          <Form.Control.Feedback
            type="invalid">
            {errors.boxes ? errors.boxes : null}
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel
          label="Количество единиц продукции"
          controlId="count"
          className="output-form__field">

          <Form.Control
            name="count"
            type="number"
            placeholder="Введите количество единиц продукции"
            min={0}
            value={values.count}
            onChange={f.handleChange}
            isInvalid={touched.count && !!errors.count}
          />

          <Form.Control.Feedback
            type="invalid">
            {errors.souffleMixes ? errors.souffleMixes : null}
          </Form.Control.Feedback>
        </FloatingLabel>
      </div>

      <div
        className="output-form__field">

        {values.rolls.map((roll, index) => {
          const rollsErrors = (errors.rolls?.length && errors.rolls[index]) || {};
          const rollsTouched = (touched.rolls?.length && touched.rolls[index]) || {};
          const rollsValues = (values.rolls?.length && values.rolls[index]) || {};
          const isRollFieldDisabled = index < rollsArrayLength - 1;

          return (
            <div
              key={`roll-id-${roll.id}`}
              className="output-form__wrapper">

              <p
                className="output-form__subtitle">
                Рулон

                {' '}

                {index + 1}
              </p>

              <div
                className="output-form__grid">

                <Button
                  type="button"
                  variant="danger"
                  title="Удалить рулон"
                  onClick={() => onRemoveRollHandle(roll.id)}
                  className="output-form__remove">
                  <XLg />
                </Button>

                <FloatingLabel
                  label="Вес на начало дня (г)"
                  controlId={`rolls.${index}.rollWeightStart`}>

                  <Form.Control
                    name={`rolls.${index}.rollWeightStart`}
                    type="number"
                    placeholder="Введите вес на начало дня (г)"
                    min={0}
                    readOnly={isRollFieldDisabled}
                    onChange={f.handleChange}
                    value={rollsValues.rollWeightStart}
                    isInvalid={rollsErrors.rollWeightStart && rollsTouched.rollWeightStart}
                  />

                  <Form.Control.Feedback
                    type="invalid">
                    {rollsErrors.rollWeightStart ? rollsErrors.rollWeightStart : null}
                  </Form.Control.Feedback>
                </FloatingLabel>

                <FloatingLabel
                  label="Вес на конец дня (г)"
                  controlId={`rolls.${index}.rollWeightEnd`}>

                  <Form.Control
                    name={`rolls.${index}.rollWeightEnd`}
                    type="number"
                    placeholder="Введите вес на конец дня (г)"
                    min={0}
                    readOnly={isRollFieldDisabled}
                    onChange={f.handleChange}
                    value={rollsValues.rollWeightEnd}
                    isInvalid={rollsErrors.rollWeightEnd && rollsTouched.rollWeightEnd}
                  />

                  <Form.Control.Feedback
                    type="invalid">
                    {rollsErrors.rollWeightEnd ? rollsErrors.rollWeightEnd : null}
                  </Form.Control.Feedback>
                </FloatingLabel>

                <FloatingLabel
                  label="Вес втулки (г)"
                  controlId={`rolls.${index}.sleeveWeight`}>

                  <Form.Control
                    name={`rolls.${index}.sleeveWeight`}
                    type="number"
                    placeholder="Введите вес втулки (г)"
                    min={0}
                    readOnly={isRollFieldDisabled}
                    disabled={rollsValues.rollWeightEnd !== 0}
                    onChange={f.handleChange}
                    value={rollsValues.sleeveWeight}
                    isInvalid={rollsErrors.sleeveWeight && rollsTouched.sleeveWeight}
                  />

                  <Form.Control.Feedback
                    type="invalid">
                    {rollsErrors.sleeveWeight ? rollsErrors.sleeveWeight : null}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
            </div>
          );
        })}

        <Button
          onClick={onAddNewRollHandle}
          variant="outline-primary"
          disabled={!isAddRollButtonEnabled}
          className="output-form__field-button">

          <PlusLg
            className="button-icon"
          />

          Добавить рулон
        </Button>
      </div>

      <Button
        type="submit"
        className="output-form__control">
        {selectedNomenclatureId ? 'Сохранить' : 'Добавить в отчёт'}
      </Button>

      {selectedNomenclatureId && (
        <Button
          type="button"
          onClick={resetData}
          variant="outline-primary"
          className="output-form__control">
          Отменить
        </Button>
      )}
    </Form>
  );
}

export default OutputForm;
