import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import fetchData from '../thunks/dataFetchThunk';

const nomenclaturesAdapter = createEntityAdapter();
const initialState = nomenclaturesAdapter.getInitialState({ selectedNomenclatureId: null });
const nomenclaturesSlice = createSlice({
  name: 'nomenclatures',
  initialState,
  reducers: {
    addNomenclature: nomenclaturesAdapter.addOne,
    editNomenclature: nomenclaturesAdapter.updateOne,
    removeNomenclature: (state, action) => {
      nomenclaturesAdapter.removeOne(state, action.payload);
    },
    selectNomenclature: (state, action) => {
      state.selectedNomenclatureId = action.payload; // eslint-disable-line no-param-reassign
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.fulfilled, (state, { payload }) => {
        const { nomenclatures } = payload;
        const newData = {};
        // eslint-disable-next-line no-return-assign
        nomenclatures.map((item, index) => newData[index + 1] = item);
        nomenclaturesAdapter.addMany(state, newData);
      });
  },
});
export const {
  addNomenclature,
  editNomenclature,
  removeNomenclature,
  selectNomenclature,
} = nomenclaturesSlice.actions;
export const selectors = nomenclaturesAdapter.getSelectors((state) => state.nomenclatures);
export default nomenclaturesSlice.reducer;
