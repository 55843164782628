import React from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import clsx from 'clsx';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { changeLoaderVisibility } from '../../../../slices/loaderSlice';
import getLastId from '../../../../hooks/getLastId';
import routes, { getRoutes } from '../../../../utils/routes';
import { addTurn, selectTurn } from '../../../../slices/turnSlice';
import setData from '../../../../hooks/setData';
import turnTypes from '../types/turnTypes';
import './turnsForm.css';

function TurnsForm(props) {
  const { className } = props;
  const dispatch = useDispatch();

  const SignupSchema = yup.object({
    turnType: yup.string()
      .required('Поле должно быть заполнено'),
    selectedDate: yup.string()
      .required('Поле должно быть заполнено'),
  });

  const f = useFormik({
    initialValues: { turnType: '', selectedDate: '' },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      const date = new Date(new Date().getTime() + 7 * 3600 * 1000);
      const user = JSON.parse(localStorage.getItem('user'));
      const startDate = date.toISOString().split('.')[0];

      dispatch(changeLoaderVisibility(true));

      try {
        const lastId = await getLastId(getRoutes.getLastTurnId());
        const id = Number(lastId) + 1;

        const data = {
          id,
          startDate,
          type: values.turnType,
          author: user.name,
          selectedDate: values.selectedDate,
        };

        const action = () => dispatch(addTurn({
          ...data,
          endDate: '',
          isClosed: '0',
        }));

        await setData(routes.setTurnPath(), action, data);

        dispatch(selectTurn(id));
      } catch (err) {
        if (err.isAxiosError && err.response.status === 401) {
          toast.error('Что-то пошло не так, обратитесь к разработке');
          return;
        }
        throw err;
      }

      dispatch(changeLoaderVisibility(false));
    },
  });

  return (
    <form
      onSubmit={f.handleSubmit}
      className={clsx(
        className,
        'turns-form',
      )}>

      <div
        className="turns-form__item">

        {Object.keys(turnTypes).map((key) => (
          <Form.Check
            key={`turn-${key}`}
            inline
            label={turnTypes[key]}
            name="turnType"
            type="radio"
            onChange={() => f.setFieldValue('turnType', turnTypes[key])}
            checked={f.values.turnType === turnTypes[key]}
            id={`turn-${key}`}
            isInvalid={f.touched.turnType && !!f.errors.turnType}
          />
        ))}
      </div>

      <FloatingLabel
        label="Дата смены"
        controlId="selectedDate"
        className="turns-form__item turns-form__item--field">

        <Form.Control
          name="selectedDate"
          type="date"
          placeholder="Выбрать дату"
          value={f.values.selectedDate}
          onChange={f.handleChange}
          isInvalid={f.touched.selectedDate && !!f.errors.selectedDate}
        />

        <Form.Control.Feedback
          type="invalid">
          {f.errors.selectedDate ?? f.errors.selectedDate}
        </Form.Control.Feedback>
      </FloatingLabel>

      <Button
        type="submit"
        className="turns-form__control">
        Открыть смену
      </Button>
    </form>
  );
}

export default TurnsForm;
