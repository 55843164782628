import axios from 'axios';
import { toast } from 'react-toastify';

const getLastId = async (path) => {
  try {
    const res = await axios.get(path);
    if (res.data.status === 200) {
      return res.data.id;
    }
    toast.error(res.data.message);
  } catch (err) {
    if (err.isAxiosError && err.response.status === 401) {
      toast.error('Что-то пошло не так, обратитесь к разработке');
      return null;
    }
    throw err;
  }
  return null;
};

export default getLastId;
