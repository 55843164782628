import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const loaderAdapter = createEntityAdapter();
const initialState = loaderAdapter.getInitialState({ isLoaderVisible: false });
const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    changeLoaderVisibility: (state, action) => {
      state.isLoaderVisible = action.payload; // eslint-disable-line no-param-reassign
    },
  },
});
export const {
  changeLoaderVisibility,
} = loaderSlice.actions;
export const selectors = loaderAdapter.getSelectors((state) => state.loader);
export default loaderSlice.reducer;
