import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import fetchData from '../thunks/dataFetchThunk';

const operationsAdapter = createEntityAdapter();
const initialState = operationsAdapter.getInitialState({ selectedOperationId: null });
const operationsSlice = createSlice({
  name: 'operations',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.fulfilled, (state, { payload }) => {
        const { operations } = payload;
        const newData = {};
        // eslint-disable-next-line no-return-assign
        operations.map((item, index) => newData[index + 1] = item);
        operationsAdapter.addMany(state, newData);
      });
  },
});
export const selectors = operationsAdapter.getSelectors((state) => state.operations);
export default operationsSlice.reducer;
