import React, { useEffect, useRef, useState } from 'react';
import './login.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { FloatingLabel, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import routes from '../../../utils/routes';
import useAuth from '../../../hooks/useAuth';
import Logo from '../../logo';

function LoginPage() {
  const auth = useAuth();
  const [authFailed, setAuthFailed] = useState(false);
  const inputRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  const SignupSchema = yup.object({
    username: yup.string()
      .required('Поле должно быть заполнено'),
    password: yup.string()
      .required('Поле должно быть заполнено'),
  });

  const f = useFormik({
    initialValues: { username: '', password: '' },
    validationSchema: SignupSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      setAuthFailed(false);
      try {
        const params = new FormData();
        Object.keys(values).map((key) => params.append(key, values[key]));
        const res = await axios.post(routes.loginPath(), params);
        if (res.data.status === 200) {
          // eslint-disable-next-line no-undef
          localStorage.setItem('user', JSON.stringify(res.data));
          auth.logIn();
          toast.success(res.data.message);
          navigate(routes.mainPage());
        } else {
          setAuthFailed(true);
          inputRef.current.focus();
          toast.error(res.data.message);
        }
      } catch (err) {
        if (err.isAxiosError && err.response.status === 401) {
          setAuthFailed(true);
          inputRef.current.focus();
          toast.error('Что-то пошло не так, обратитесь к разработке');
          return;
        }
        throw err;
      }
    },
  });

  return (
    <div
      className="login-page site-container">

      <Form
        onSubmit={f.handleSubmit}
        className="login-page__form form">

        <Logo
          className="form__logo"
        />

        <FloatingLabel
          label="Логин"
          controlId="username"
          className="form__field">

          <Form.Control
            name="username"
            type="text"
            placeholder="Введите логин"
            ref={inputRef}
            value={f.values.username}
            onChange={f.handleChange}
            isInvalid={(f.touched.username && !!f.errors.username) || authFailed}
          />

          <Form.Control.Feedback
            type="invalid">
            {f.errors.username ? f.errors.username : null}
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel
          label="Пароль"
          controlId="password"
          className="form__field">

          <Form.Control
            name="password"
            type="password"
            placeholder="Введите пароль"
            value={f.values.password}
            onChange={f.handleChange}
            isInvalid={(f.touched.password && !!f.errors.password) || authFailed}
          />

          <Form.Control.Feedback
            type="invalid">
            {f.errors.password ? f.errors.password : 'Неверно введены логин или пароль.'}
          </Form.Control.Feedback>
        </FloatingLabel>

        <Button
          variant="primary"
          type="submit"
          size="lg"
          className="form__control">
          Войти
        </Button>
      </Form>
    </div>
  );
}

export default LoginPage;
