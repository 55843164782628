import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import fetchData from '../thunks/dataFetchThunk';

const categoriesAdapter = createEntityAdapter();
const initialState = categoriesAdapter.getInitialState({ selectedCategoryId: null });
const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    addCategory: categoriesAdapter.addOne,
    editCategory: categoriesAdapter.updateOne,
    removeCategory: (state, action) => {
      categoriesAdapter.removeOne(state, action.payload);
    },
    selectCategory: (state, action) => {
      state.selectedCategoryId = action.payload; // eslint-disable-line no-param-reassign
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.fulfilled, (state, { payload }) => {
        const { categories } = payload;
        const newData = {};
        // eslint-disable-next-line no-return-assign
        categories.map((item, index) => newData[index + 1] = item);
        categoriesAdapter.addMany(state, newData);
      });
  },
});
export const {
  addCategory,
  removeCategory,
  editCategory,
  selectCategory,
} = categoriesSlice.actions;
export const selectors = categoriesAdapter.getSelectors((state) => state.categories);
export default categoriesSlice.reducer;
