import { configureStore } from '@reduxjs/toolkit';
import nomenclaturesReducer from './nomenclaturesSlice';
import nomenclaturesFieldsReducer from './nomenclaturesFieldsSlice';
import nomenclaturesRollsReducer from './nomenclaturesRollsSlice';
import turnsReducer from './turnSlice';
import createdOperationsReducer from './createdOperationsSlice';
import operationsReducer from './operationsSlice';
import productsReducer from './productsSlice';
import categoriesReducer from './categoriesSlice';
import tastesReducer from './tastesSlice';
import loaderReducer from './loaderSlice';

export default configureStore({
  reducer: {
    loader: loaderReducer,
    turns: turnsReducer,
    operations: operationsReducer,
    createdOperations: createdOperationsReducer,
    nomenclatures: nomenclaturesReducer,
    nomenclaturesFields: nomenclaturesFieldsReducer,
    nomenclaturesRolls: nomenclaturesRollsReducer,
    products: productsReducer,
    categories: categoriesReducer,
    tastes: tastesReducer,
  },
});
