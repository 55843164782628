import {
  BrowserRouter as Router,
  Route, Routes, useLocation, Navigate,
} from 'react-router-dom';
import React, { useEffect } from 'react';
import './styles/bootstrap.min.css';
import './styles/normalize.css';
import './app.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import routes from './utils/routes';
import LoginPage from './components/pages/login/login';
import NotFoundPage from './components/pages/notFound/notFound';
import useAuth from './hooks/useAuth';
import MainPage from './components/pages/main';
import ManagerPage from './components/pages/manager/manager';
import fetchData from './thunks/dataFetchThunk';

function PrivateRoute({ children }) {
  const auth = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  return (
    auth.loggedIn ? children : <Navigate to={routes.loginPage()} state={{ from: location }} />
  );
}

function AuthRoute({ children }) {
  const { loggedIn } = useAuth();
  return (
    loggedIn ? <Navigate to={routes.mainPage()} /> : children
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path={routes.loginPage()} element={<AuthRoute><LoginPage /></AuthRoute>} />
        <Route path={routes.mainPage()} element={<PrivateRoute><MainPage /></PrivateRoute>} />
        <Route path={routes.managerPage()} element={<PrivateRoute><ManagerPage /></PrivateRoute>} />
        <Route path={routes.notFoundPage()} element={<NotFoundPage />} />
      </Routes>

      <ToastContainer
        position="top-center"
        autoClose={500}
      />
    </Router>
  );
}

export default App;
