import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { removeProduct } from './productsSlice';
import fetchData from '../thunks/dataFetchThunk';

const tastesAdapter = createEntityAdapter();
const initialState = tastesAdapter.getInitialState({ selectedTasteId: null });
const tastesSlice = createSlice({
  name: 'tastes',
  initialState,
  reducers: {
    addTaste: tastesAdapter.addOne,
    editTaste: tastesAdapter.updateOne,
    removeTaste: (state, action) => {
      tastesAdapter.removeOne(state, action.payload);
    },
    selectTaste: (state, action) => {
      state.selectedTasteId = action.payload; // eslint-disable-line no-param-reassign
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.fulfilled, (state, { payload }) => {
        const { tastes } = payload;
        const renderedTastes = {};
        // eslint-disable-next-line no-return-assign
        tastes.map((item, index) => renderedTastes[index + 1] = item);
        tastesAdapter.addMany(state, renderedTastes);
      })
      .addCase(removeProduct, (state, action) => {
        const removedProductId = action.payload;
        const entities = Object.values(state.entities);
        const restEntities = entities.filter((e) => e.productId !== removedProductId);
        tastesAdapter.setAll(state, restEntities);
      });
  },
});
export const {
  addTaste,
  removeTaste,
  editTaste,
  selectTaste,
} = tastesSlice.actions;
export const selectors = tastesAdapter.getSelectors((state) => state.tastes);
export default tastesSlice.reducer;
