import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  addProduct, editProduct, selectProduct, selectors,
} from '../../../../../slices/productsSlice';
import {
  selectors as categoriesSelectors,
} from '../../../../../slices/categoriesSlice';
import '../editors.css';
import routes, { getRoutes } from '../../../../../utils/routes';
import setData from '../../../../../hooks/setData';
import { changeLoaderVisibility } from '../../../../../slices/loaderSlice';
import getLastId from '../../../../../hooks/getLastId';

function ProductsEditor(props) {
  const { className, onEditorClose } = props;
  const inputRef = useRef();
  const dispatch = useDispatch();
  const categories = useSelector(categoriesSelectors.selectAll);
  const products = useSelector(selectors.selectAll);
  const selectedProductId = useSelector((state) => state.products.selectedProductId);
  const selectedProduct = products.filter((Product) => Product.id === selectedProductId)[0];

  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  const SignupSchema = yup.object({
    name: yup.string()
      .required('Поле должно быть заполнено'),
    categoryId: yup.number()
      .required('Поле должно быть заполнено'),
    code: yup.string()
      .required('Поле должно быть заполнено'),
  });

  const initialValues = selectedProductId
    ? { name: selectedProduct.name, categoryId: selectedProduct.categoryId, code: selectedProduct.code }
    : { name: '', categoryId: '', code: '' };

  const f = useFormik({
    initialValues,
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      const { name, categoryId, code } = values;

      dispatch(changeLoaderVisibility(true));
      const lastId = await getLastId(getRoutes.getLastProductsId());
      const id = Number(lastId) + 1;
      dispatch(changeLoaderVisibility(false));

      const actions = {
        add: addProduct({
          id, name, categoryId, code,
        }),
        edit: editProduct({
          id: selectedProductId,
          changes: {
            name,
            categoryId,
            code,
          },
        }),
      };
      values.id = selectedProductId || id;
      values.type = selectedProductId ? 'edit' : 'add';
      const action = () => dispatch(selectedProductId ? actions.edit : actions.add);
      dispatch(changeLoaderVisibility(true));
      await setData(routes.setProductsPath(), action, values, inputRef);
      dispatch(changeLoaderVisibility(false));
      dispatch(selectProduct(null));
      onEditorClose();
    },
  });

  return (
    <form
      onSubmit={f.handleSubmit}
      className={clsx(
        className,
        'editor',
      )}>

      <div
        className="editor__fields">

        <FloatingLabel
          label="Имя продукта"
          controlId="name"
          className="editor__field">

          <Form.Control
            name="name"
            type="text"
            placeholder="Введите имя продукта"
            ref={inputRef}
            value={f.values.name}
            onChange={f.handleChange}
            isInvalid={f.touched.name && !!f.errors.name}
          />

          <Form.Control.Feedback
            type="invalid">
            {f.errors.name ? f.errors.name : null}
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel
          label="Код продукта"
          controlId="code"
          className="editor__field">

          <Form.Control
            name="code"
            type="text"
            placeholder="Введите код продукта"
            value={f.values.code}
            onChange={f.handleChange}
            isInvalid={f.touched.code && !!f.errors.code}
          />

          <Form.Control.Feedback
            type="invalid">
            {f.errors.code ? f.errors.code : null}
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel
          label="Категория"
          controlId="categoryId"
          className="editor__field">

          <Form.Select
            aria-label="Категория"
            name="categoryId"
            type="text"
            placeholder="Введите имя продукта"
            value={f.values.categoryId}
            onChange={f.handleChange}
            isInvalid={f.touched.categoryId && !!f.errors.categoryId}>

            <option>Выберете категорию</option>
            {categories.map(({ id, name }) => (
              <option key={`product-categories-${id}`} value={id}>{name}</option>
            ))}
          </Form.Select>

          <Form.Control.Feedback
            type="invalid">
            {f.errors.categoryId ? f.errors.categoryId : null}
          </Form.Control.Feedback>
        </FloatingLabel>
      </div>

      <div
        className="editor__controls">

        <Button
          type="submit"
          className="editor__control">
          Добавить продукт
        </Button>

        <Button
          type="button"
          variant="outline-primary"
          onClick={onEditorClose}
          className="editor__control">
          Отмена
        </Button>
      </div>
    </form>
  );
}

export default ProductsEditor;
