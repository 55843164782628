import React from 'react';
import './header.css';
import { Button } from 'react-bootstrap';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { ArrowReturnLeft, BoxArrowLeft, Gear } from 'react-bootstrap-icons';
import Logo from '../logo';
import routes from '../../utils/routes';
import useAuth from '../../hooks/useAuth';

function Header(props) {
  const { className } = props;
  const location = useLocation();
  const buttonHref = location.pathname === routes.managerPage() ? routes.mainPage() : routes.managerPage();
  const buttonText = location.pathname === routes.managerPage() ? 'Назад' : 'Админ панель';
  const buttonIcon = location.pathname === routes.managerPage()
    ? (
      <ArrowReturnLeft
        className="button-icon"
      />
    ) : (
      <Gear
        className="button-icon"
      />
    );
  const auth = useAuth();
  const { permission } = JSON.parse(localStorage.getItem('user'));

  return (
    <header
      className={clsx(
        className,
        'header',
      )}>

      <a
        href={routes.mainPage()}
        title="На главную"
        className="header__logo-link">

        <Logo
          className="header__logo"
        />
      </a>

      <h1
        className="header__title">
        Производство
      </h1>

      <div
        className="header__controls">

        {permission > 900 && (
          <Link
            to={buttonHref}
            className="header__control btn btn-outline-primary">

            {buttonIcon}

            <span
              className="button-text">
              {buttonText}
            </span>
          </Link>
        )}

        <Button
          onClick={auth.logOut}
          variant="outline-primary"
          className="header__control">

          <BoxArrowLeft
            className="button-icon"
          />

          <span
            className="button-text">
            Выйти из аккаунта
          </span>
        </Button>
      </div>
    </header>
  );
}

export default Header;
