import React from 'react';
import { Button, Table } from 'react-bootstrap';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, selectTurn } from '../../../../slices/turnSlice';

function TurnsTable(props) {
  const { className } = props;
  const dispatch = useDispatch();
  const turns = useSelector(selectors.selectAll);

  const onTurnEdit = (id) => {
    dispatch(selectTurn(id));
  };

  const sortedTurns = turns.sort((a, b) => {
    if (Number(a.id) < Number(b.id)) {
      return -1;
    }
    if (Number(a.id) > Number(b.id)) {
      return 1;
    }
    return 0;
  });

  return (
    <Table
      striped
      hover
      responsive="sm"
      className={clsx(
        className,
        'turns-table',
      )}>

      <thead>
        <tr>
          <th>#</th>
          <th>Тип</th>
          <th>Дата смены</th>
          <th>Дата открытия</th>
          <th>Дата закрытия</th>
          <th>Автор</th>
          <th>Номер смены</th>
          <th>Статус</th>
          <th>Управление</th>
        </tr>
      </thead>

      <tbody>
        {sortedTurns.map((turn, index) => (
          <tr
            key={`turn-${turn.id}`}>
            <td>{index + 1}</td>
            <td>{turn.type}</td>
            <td>{turn.selectedDate}</td>
            <td>{turn.startDate}</td>
            <td>{turn.endDate}</td>
            <td>{turn.author}</td>
            <td>{turn.id}</td>
            <td>{turn.isClosed === '1' ? 'Закрыта' : 'Открыта'}</td>
            <td>
              <Button
                onClick={() => onTurnEdit(turn.id)}
                variant={turn.isClosed !== '1' ? 'primary' : 'outline-primary'}
                size="sm">
                {turn.isClosed !== '1' ? 'Изменить данные смены' : 'Просмотреть'}
              </Button>
            </td>
          </tr>
        ))}
        {sortedTurns.length === 0 && (
        <tr>
          <td
            colSpan={9}>
            Смены отсутствуют, добавьте новую смену
          </td>
        </tr>
        )}
      </tbody>
    </Table>
  );
}

export default TurnsTable;
