import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { removeCategory } from './categoriesSlice';
import fetchData from '../thunks/dataFetchThunk';

const productsAdapter = createEntityAdapter();
const initialState = productsAdapter.getInitialState({ selectedProductId: null });
const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    addProduct: productsAdapter.addOne,
    editProduct: productsAdapter.updateOne,
    removeProduct: (state, action) => {
      productsAdapter.removeOne(state, action.payload);
    },
    selectProduct: (state, action) => {
      state.selectedProductId = action.payload; // eslint-disable-line no-param-reassign
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.fulfilled, (state, { payload }) => {
        const { products } = payload;
        const renderedProducts = {};
        // eslint-disable-next-line no-return-assign
        products.map((item, index) => renderedProducts[index + 1] = item);
        productsAdapter.addMany(state, renderedProducts);
      })
      .addCase(removeCategory, (state, action) => {
        const removedCategoryId = action.payload;
        const entities = Object.values(state.entities);
        const restEntities = entities.filter((e) => e.categoryId !== removedCategoryId);
        productsAdapter.setAll(state, restEntities);
      });
  },
});
export const {
  addProduct,
  removeProduct,
  editProduct,
  selectProduct,
} = productsSlice.actions;
export const selectors = productsAdapter.getSelectors((state) => state.products);
export default productsSlice.reducer;
