import React, { useState } from 'react';
import './products.css';
import {
  Button, ListGroup, ListGroupItem, Modal, Table,
} from 'react-bootstrap';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import Step from './step/step';
import Product from './product/product';
import OutputForm from './outputForm/outputForm';
import { removeNomenclature, selectors, selectNomenclature } from '../../../slices/nomenclaturesSlice';
import { editOperation, selectOperation, selectors as createdOperationsSelectors } from '../../../slices/createdOperationsSlice';
import { selectors as categoriesSelectors } from '../../../slices/categoriesSlice';
import { selectors as productsSelectors } from '../../../slices/productsSlice';
import { selectors as tastesSelectors } from '../../../slices/tastesSlice';
import { selectors as rollsSelectors } from '../../../slices/nomenclaturesRollsSlice';
import { selectors as fieldsSelectors } from '../../../slices/nomenclaturesFieldsSlice';
import { changeLoaderVisibility } from '../../../slices/loaderSlice';
import setData from '../../../hooks/setData';
import routes from '../../../utils/routes';

function ProductsPage(props) {
  const { className, operationId, isTurnClosed } = props;
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedTasteId, setSelectedTasteId] = useState(null);
  const categories = useSelector(categoriesSelectors.selectAll);
  const products = useSelector(productsSelectors.selectAll);
  const tastes = useSelector(tastesSelectors.selectAll);
  const createdOperations = useSelector(createdOperationsSelectors.selectAll);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [removeItemId, setRemoveItemId] = useState(false);
  const removeModalHandleClose = () => setShowRemoveModal(false);
  const removeModalHandleShow = () => setShowRemoveModal(true);

  const selectedNomenclatureId = useSelector((state) => state.nomenclatures.selectedNomenclatureId);
  const selectedOperationId = useSelector((state) => state.createdOperations.selectedOperationId);

  const onCategoryChange = (categoryId) => {
    setSelectedProductId(null);
    setSelectedTasteId(null);
    setSelectedCategoryId(categoryId);
  };

  const onProductChange = (productId) => {
    setSelectedTasteId(null);
    setSelectedProductId(productId);
  };

  const selectedCategory = categories.find((item) => item.id === selectedCategoryId);
  const selectedProduct = products.find((item) => item.id === selectedProductId);
  const selectedTaste = tastes.find((item) => item.id === selectedTasteId);

  const nomenclatures = useSelector(selectors.selectAll);
  const nomenclaturesFields = useSelector(fieldsSelectors.selectAll);
  const nomenclaturesRolls = useSelector(rollsSelectors.selectAll);
  const selectedNomenclatures = nomenclatures
    .filter((nomenclature) => nomenclature.createdOperationId === operationId);

  const selectedOperation = createdOperations
    .filter((operation) => operation.id === operationId)[0];

  const dispatch = useDispatch();

  const resetData = () => {
    setSelectedCategoryId(null);
    setSelectedProductId(null);
    setSelectedTasteId(null);
    dispatch(selectNomenclature(null));
  };

  const onBackHandle = () => {
    dispatch(selectOperation(null));
    resetData();
  };

  const onOperationSend = async () => {
    const action = () => dispatch(editOperation({
      id: selectedOperationId,
      changes: { isSent: true },
    }));
    const user = JSON.parse(localStorage.getItem('user'));
    const preparedValues = {
      id: selectedOperationId,
      sender: user.name,
    };

    dispatch(changeLoaderVisibility(true));
    await setData(routes.sendEmail(), action, preparedValues);
    dispatch(changeLoaderVisibility(false));

    onBackHandle();
  };

  const onNomenclatureDeleteHandle = (id) => {
    setRemoveItemId(id);
    removeModalHandleShow();
  };

  const onNomenclatureDelete = async (id) => {
    const action = () => dispatch(removeNomenclature(id));
    const preparedValues = { id, type: 'delete' };
    dispatch(changeLoaderVisibility(true));
    await setData(routes.setNomenclaturePath(), action, preparedValues, null, {}, true);
    dispatch(changeLoaderVisibility(false));
    removeModalHandleClose();
  };

  const onNomenclatureEdit = (id, categoryId, productId, tasteId) => {
    setSelectedCategoryId(categoryId);
    setSelectedProductId(productId);
    setSelectedTasteId(tasteId);
    dispatch(selectNomenclature(id));
  };

  const { permission } = JSON.parse(localStorage.getItem('user'));

  const isEditMode = selectedNomenclatureId !== null;
  const isReadOnly = isTurnClosed && permission < 701;
  const isSelectProductAvailable = selectedOperation.isSent !== '1';
  return (
    <div
      className={clsx(
        className,
        'products-page',
        {
          'product-page--no-gap': selectedOperation.isSent === '1' && !isEditMode,
        },
      )}>

      {!isReadOnly && (
        <div
          className="products-page__steps">

          {!selectedNomenclatureId && isSelectProductAvailable && (
            <>
              <h2
                className="products-page__title">
                Добавление номенклатуры
              </h2>

              <Step
                isFinished={selectedCategoryId !== null}
                number={1}
                title="Выбрать категорию"
                className="products-page__step">

                {categories.map((category) => (
                  <Button
                    key={`category-id-${category.id}`}
                    variant={selectedCategoryId === category.id ? 'success' : 'outline-primary'}
                    type="button"
                    onClick={() => onCategoryChange(category.id)}>
                    {category.name}
                  </Button>
                ))}
              </Step>
            </>
          )}

          {(selectedCategoryId && !selectedNomenclatureId) ? (
            <Step
              isFinished={selectedProductId !== null}
              number={2}
              title="Выбрать продукт"
              className="products-page__step">

              {products
                .filter((product) => product.categoryId === selectedCategoryId)
                .map((product) => (
                  <Button
                    key={`product-id-${product.id}`}
                    variant={selectedProductId === product.id ? 'success' : 'outline-primary'}
                    type="button"
                    onClick={() => onProductChange(product.id)}>
                    {product.name}
                  </Button>
                ))}
            </Step>
          ) : null}

          {(selectedProductId && !selectedNomenclatureId) && (
            <Step
              number={3}
              isGrid
              title="Выбрать вкус"
              isFinished={selectedTasteId !== null}
              className="products-page__step">

              {tastes
                .filter((taste) => taste.productId === selectedProductId)
                .map((taste) => (
                  <Product
                    key={`productTaste-id-${taste.id}`}
                    title={taste.name}
                    isSelectButtonShown="true"
                    isSelected={selectedTasteId === taste.id}
                    onClick={() => setSelectedTasteId(taste.id)}
                    img={taste.image ? `/images/${taste.productId}/${taste.image}` : '/images/no-image.png'}
                  />
                ))}
            </Step>
          )}

          {(selectedTasteId || selectedNomenclatureId) && (
            <Step
              number={4}
              title="Заполнить данные"
              className="products-page__step">

              <OutputForm
                resetData={resetData}
                selectedProduct={selectedProduct}
                selectedTaste={selectedTaste}
                selectedCategory={selectedCategory}
                operationId={operationId}
              />
            </Step>
          )}

          {isSelectProductAvailable && (
            <div
              className="products-page__controls">

              <Button
                onClick={onBackHandle}
                className="products-page__control">
                Назад
              </Button>
            </div>
          )}
        </div>
      )}

      <div
        className={clsx(
          'products-page__total',
          {
            'products-page__total--full-width': isReadOnly || (selectedOperation.isSent === '1' && !isEditMode),
          },
        )}>

        <h2
          className="products-page__title">
          Добавленные номенклатуры

          <br />

          {`Операция/документ #${selectedOperationId}`}
        </h2>

        {selectedNomenclatures.length > 0
          ? (
            <ListGroup
              as="ol"
              numbered>
              {selectedNomenclatures.map((item) => {
                const currentTaste = tastes
                  // eslint-disable-next-line max-len
                  .filter((taste) => (taste.id === item.tasteId) && (taste.productId === item.productId))[0];

                const currentProduct = products
                  .filter((product) => product.id === item.productId)[0];

                const fields = nomenclaturesFields.filter((field) => field.nomenclatureId === item.id)[0];
                const rolls = nomenclaturesRolls.filter((roll) => roll.nomenclatureId === item.id);

                const { addDate } = item;

                const itemFields = (
                  <Table
                    size="sm"
                    responsive="sm"
                    striped
                    hover>

                    <thead>
                      <tr>
                        <th>Поле</th>
                        <th>Значение</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>Дата добавления</td>
                        <td>{addDate}</td>
                      </tr>
                      <tr>
                        <td>Количество замесов теста</td>
                        <td>{fields.doughBatches}</td>
                      </tr>
                      <tr>
                        <td>Количество замесов суфле</td>
                        <td>{fields.souffleMixes}</td>
                      </tr>
                      <tr>
                        <td>Количество боксов</td>
                        <td>{fields.boxes}</td>
                      </tr>
                      <tr>
                        <td>Количество единиц продукции</td>
                        <td>{fields.count}</td>
                      </tr>
                    </tbody>
                  </Table>
                );

                const itemRolls = rolls.length > 0 ? (
                  <Table
                    responsive="sm"
                    size="sm"
                    striped
                    bordered
                    hover>

                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Вес на начало дня (г)</th>
                        <th>Вес на конец дня (г)</th>
                        <th>Вес втулки (г)</th>
                      </tr>
                    </thead>

                    <tbody>
                      {rolls.map((roll, index) => (
                        <tr
                          key={`roll-id-${roll.id}`}>
                          <td>{index + 1}</td>
                          <td>{roll.rollWeightStart}</td>
                          <td>{roll.rollWeightEnd}</td>
                          <td>{roll.sleeveWeight}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : null;
                return (
                  <ListGroupItem
                    as="li"
                    key={`pr${item.id}`}>

                    <Product
                      title={`${currentProduct.name}, ${currentTaste.name}`}
                      isSelectButtonShown={false}
                      isDeleteButtonShown={(!isTurnClosed && selectedOperation.isSent !== '1') || (isTurnClosed && permission > 700)}
                      onDeleteButtonClick={() => onNomenclatureDeleteHandle(item.id)}
                      isUpdating={selectedNomenclatureId === item.id}
                      isUpdateButtonShown={!isTurnClosed || permission > 700}
                      isUpdateDisabled={!!selectedNomenclatureId}
                      onUpdateButtonClick={() => onNomenclatureEdit(item.id, item.categoryId, item.productId, item.tasteId)}
                      fields={itemFields}
                      rolls={itemRolls}
                      img={currentTaste.image ? `/images/${currentProduct.id}/${currentTaste.image}` : '/images/no-image.png'}
                    />
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          ) : (
            <p
              className="products-page__description">
              Пока ничего не добавлено
            </p>
          )}

        <div
          className="products-page__controls">

          <Button
            onClick={onBackHandle}
            className="products-page__control">
            Назад
          </Button>

          {!isReadOnly && (
            <Button
              onClick={onOperationSend}
              variant="success"
              disabled={selectedNomenclatures.length === 0}
              className="products-page__control">
              {selectedOperation.isSent !== '1' ? 'Отправить отчет' : 'Отправить отчет повторно'}
            </Button>
          )}
        </div>
      </div>

      <Modal
        show={showRemoveModal}
        onHide={removeModalHandleClose}>

        <Modal.Header>

          <Modal.Title>
            Удалить номенклатуру
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          Это действие необратимо. Вы уверены?
        </Modal.Body>

        <Modal.Footer>

          <Button
            variant="danger"
            onClick={() => onNomenclatureDelete(removeItemId)}
            type="button">
            Удалить
          </Button>

          <Button
            variant="outline-primary"
            onClick={removeModalHandleClose}
            type="button">
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ProductsPage;
