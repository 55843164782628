import React from 'react';
import './product.css';
import clsx from 'clsx';
import { Button } from 'react-bootstrap';

function Product(props) {
  const {
    className,
    img,
    title,
    onClick,
    isSelected,
    isSelectButtonShown,
    fields,
    rolls,
    isDeleteButtonShown,
    onDeleteButtonClick,
    isUpdateButtonShown,
    onUpdateButtonClick,
    isUpdating,
    isUpdateDisabled,
  } = props;
  return (
    <div
      className={clsx(
        className,
        'product',
      )}>

      <img
        src={img}
        alt={title}
        className="product__image"
      />

      <div
        className="product__title">
        {title}
      </div>

      {fields && (
        <div
          className="product__description">
          {fields}
        </div>
      )}

      {rolls && (
        <>
          <h3
            className="product__subtitle">
            Рулоны упаковки
          </h3>

          <div
            className="product__description">
            {rolls}
          </div>
        </>
      )}

      {isSelectButtonShown && (
        <Button
          onClick={onClick}
          variant={isSelected ? 'success' : 'outline-primary'}
          className="product__control">
          {isSelected ? 'Выбран' : 'Выбрать'}
        </Button>
      )}

      {isUpdateButtonShown && (
        <Button
          disabled={isUpdateDisabled || isUpdating}
          variant={isUpdating ? 'success' : 'outline-primary'}
          onClick={onUpdateButtonClick}
          className="product__control">
          {isUpdating ? 'Выбран для изменения' : 'Изменить'}
        </Button>
      )}

      {isDeleteButtonShown && (
        <Button
          onClick={onDeleteButtonClick}
          variant="danger"
          disabled={isUpdateDisabled || isUpdating}
          className="product__control">
          Удалить
        </Button>
      )}
    </div>
  );
}

Product.defaultProps = {
  isSelected: false,
  isSelectButtonShown: true,
};

export default Product;
