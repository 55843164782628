import React from 'react';
import './notFound.css';

function NotFoundPage() {
  return (
    <>Страница не найдена</>
  );
}

export default NotFoundPage;
