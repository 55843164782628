import React from 'react';
import './step.css';
import clsx from 'clsx';

function Step(props) {
  const {
    className, children, number, isFinished, isGrid, title,
  } = props;
  return (
    <div
      className={clsx(
        className,
        'step',
        {
          'step--is-finished': isFinished,
        },
      )}>

      <h3
        className="step__title">
        {title}
      </h3>

      <div
        className="step__grid">

        <div
          className="step__number">
          {number}
        </div>

        <div
          className={clsx(
            'step__wrapper',
            {
              'step__wrapper--is-grid': isGrid,
            },
          )}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Step;
