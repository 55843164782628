import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import fetchData from '../thunks/dataFetchThunk';

const turnsAdapter = createEntityAdapter();
const initialState = turnsAdapter.getInitialState({ selectedTurnId: null, isLoaderVisible: true });
const turnsSlice = createSlice({
  name: 'turns',
  initialState,
  reducers: {
    addTurn: turnsAdapter.addOne,
    editTurn: turnsAdapter.updateOne,
    selectTurn: (state, action) => {
      state.selectedTurnId = action.payload; // eslint-disable-line no-param-reassign
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.fulfilled, (state, { payload }) => {
        const { turns } = payload;
        state.isLoaderVisible = false;
        const renderedTurns = {};
        // eslint-disable-next-line no-return-assign
        turns.map((item, index) => renderedTurns[index + 1] = item);
        turnsAdapter.addMany(state, renderedTurns);
      });
  },
});
export const {
  addTurn,
  editTurn,
  selectTurn,
} = turnsSlice.actions;
export const selectors = turnsAdapter.getSelectors((state) => state.turns);
export default turnsSlice.reducer;
