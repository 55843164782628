import React from 'react';
import { useSelector } from 'react-redux';
import TurnsPage from './turns/turns';
import './main.css';
import OperationsPage from './operations/operations';
import ProductsPage from './products/products';
import { selectors as turnSelectors } from '../../slices/turnSlice';
import Header from '../header/header';
import Loader from '../loader/loader';

function MainPage() {
  const selectedTurnId = useSelector((state) => state.turns.selectedTurnId);
  const selectedOperationId = useSelector((state) => state.createdOperations.selectedOperationId);
  const isTurnPageShown = selectedTurnId === null;
  const isOperationPageShown = !isTurnPageShown && (selectedOperationId === null);
  const isProductsPageShown = !isTurnPageShown && !isOperationPageShown;
  const turnsList = useSelector(turnSelectors.selectAll);
  const selectedTurn = turnsList.filter((turn) => turn.id === selectedTurnId)[0];
  const isStartLoaderVisible = useSelector((state) => state.turns.isLoaderVisible);
  const isLoaderVisible = useSelector((state) => state.loader.isLoaderVisible);

  return (
    <div
      className="main-page">

      <Header
        className="main-page__header"
      />

      <main
        className="main-page__content">

        {isTurnPageShown && (
          <TurnsPage
            className="main-page__item"
          />
        )}

        {isOperationPageShown && (
          <OperationsPage
            turnId={selectedTurnId}
            isTurnClosed={selectedTurn.isClosed === '1'}
            className="main-page__item"
          />
        )}

        {isProductsPageShown && (
          <ProductsPage
            isTurnClosed={selectedTurn.isClosed === '1'}
            operationId={selectedOperationId}
            className="main-page__item"
          />
        )}
      </main>

      {(isStartLoaderVisible || isLoaderVisible) && (
        <Loader />
      )}
    </div>
  );
}

export default MainPage;
