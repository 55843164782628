import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import fetchData from '../thunks/dataFetchThunk';

const nomenclaturesRollsAdapter = createEntityAdapter();
const initialState = nomenclaturesRollsAdapter.getInitialState({ selectedNomenclatureId: null });
const nomenclaturesRollsSlice = createSlice({
  name: 'nomenclaturesRolls',
  initialState,
  reducers: {
    addNomenclatureRolls: nomenclaturesRollsAdapter.addMany,
    editNomenclatureRolls: nomenclaturesRollsAdapter.upsertMany,
    removeNomenclatureRolls: (state, action) => {
      nomenclaturesRollsAdapter.removeMany(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.fulfilled, (state, { payload }) => {
        const { nomenclaturesRolls } = payload;
        const newData = {};
        // eslint-disable-next-line no-return-assign
        nomenclaturesRolls.map((item, index) => newData[index + 1] = item);
        nomenclaturesRollsAdapter.addMany(state, newData);
      });
  },
});
export const {
  addNomenclatureRolls,
  editNomenclatureRolls,
  removeNomenclatureRolls,
} = nomenclaturesRollsSlice.actions;
export const selectors = nomenclaturesRollsAdapter.getSelectors((state) => state.nomenclaturesRolls);
export default nomenclaturesRollsSlice.reducer;
