import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import fetchData from '../thunks/dataFetchThunk';

const createdOperationsAdapter = createEntityAdapter();
const initialState = createdOperationsAdapter.getInitialState({ selectedOperationId: null });
const createdOperationsSlice = createSlice({
  name: 'createdOperations',
  initialState,
  reducers: {
    addOperation: createdOperationsAdapter.addOne,
    editOperation: createdOperationsAdapter.updateOne,
    selectOperation: (state, action) => {
      state.selectedOperationId = action.payload; // eslint-disable-line no-param-reassign
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.fulfilled, (state, { payload }) => {
        const { createdOperations } = payload;
        const newData = {};
        // eslint-disable-next-line no-return-assign
        createdOperations.map((item, index) => newData[index + 1] = item);
        createdOperationsAdapter.addMany(state, newData);
      });
  },
});
export const {
  addOperation,
  editOperation,
  selectOperation,
} = createdOperationsSlice.actions;
export const selectors = createdOperationsAdapter.getSelectors((state) => state.createdOperations);
export default createdOperationsSlice.reducer;
